// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    background: #010d35;
    color: #fff;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 16px;
    width: 100%;
  
  }
  .header__container {
    align-items: center;
    display: flex;
    height: 100%;
  }
  .header__title {
    border-right: 1px solid #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-right: 8px;
    padding-right: 8px;
  }

  .Block-SeparateOrder__BUTTONShareOrder {
    width: 55px;
    height: 40px;
    margin-top: 7px;
    margin-right: -5px;
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/HeaderPersonal/HeaderPersonal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;;EAEb;EACA;IACE,mBAAmB;IACnB,aAAa;IACb,YAAY;EACd;EACA;IACE,4BAA4B;IAC5B,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".header {\n    background: #010d35;\n    color: #fff;\n    height: 64px;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 8px;\n    padding: 0 16px;\n    width: 100%;\n  \n  }\n  .header__container {\n    align-items: center;\n    display: flex;\n    height: 100%;\n  }\n  .header__title {\n    border-right: 1px solid #fff;\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 24px;\n    margin-right: 8px;\n    padding-right: 8px;\n  }\n\n  .Block-SeparateOrder__BUTTONShareOrder {\n    width: 55px;\n    height: 40px;\n    margin-top: 7px;\n    margin-right: -5px;\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
