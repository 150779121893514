import { FormStore } from "./form";
import { QrLinktsStore } from "./qrLinK";
import { OrdersStore } from "./orders";
import { HeightBlockStore } from "./heightBlock";

export const formStore = new FormStore();
export const qrLinkStore = new QrLinktsStore();

export const orderStore = new OrdersStore();

export const heightBlockStore = new HeightBlockStore();
