import React, {useState} from "react";

import "./HeaderPersonal.css";
import {formStore, orderStore} from "@store/index";
import classNames from "classnames";

// import PopupShare from "../PopupShare/PopupShare";

const ICON_SHARE: string = require("@assets/share.svg").default;

const HeaderPersonal = (props: any) => {
  const {wrapperClassName, headerColor} = props;

  const {getHeadTextOne, getheadTextTwo, getMenuColor} = orderStore;

  const {designStore,} = formStore;

  const [isOpen, setIsOpen] = useState(false);

  const ShareOrderButtonClasses = classNames({
    [wrapperClassName]: !!wrapperClassName,
    "Block-SeparateOrder__BUTTONShareOrder": true,
  });

  const HeaderClasses = classNames({
    header: true,
  });

  // const HeaderClassesWithNewStyle = classNames({
  //   header: true,
  // });

  return (
    <header
      className={HeaderClasses}
      style={{backgroundColor: headerColor}}
    >
      <div className="header__container">
        <div className="header__title">{designStore?.headTextOne}</div>
        <div className="header__table">{designStore?.headTextTwo}</div>
        {/*<img*/}
        {/*  src={ICON_SHARE}*/}
        {/*  alt="share_icon"*/}
        {/*  className={ShareOrderButtonClasses}*/}
        {/*  onClick={() => setIsOpen((isOpen: boolean) => !isOpen)}*/}
        {/*/>*/}
        {/*<PopupShare setIsOpen={setIsOpen} isOpen={isOpen} />*/}
      </div>
    </header>
  );
};

export default HeaderPersonal;
