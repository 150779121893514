// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.HeaderLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 60px;
  width: 100%;
  border-bottom: 0.1px solid #d3d3d3;
  color: #2c555b;

  position: relative;
}

.HeaderLayout__innerBlock {
  Font-family: 'DM Sans', sans-serif;
  width: 800px;
  display: flex;
  justify-content: space-between;
  font-weight: 100;
  font-size: 20px;
  text-align: right;
  color: #2c555b;
}
@media (max-width: 800px) {

  .HeaderLayout {
    padding: 0 16px 0 16px;
  }
}

@media (max-width: 450px) {

  .HeaderLayout__logo {
    position: absolute;
    left: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Header/Header.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,kCAAkC;EAClC,cAAc;;EAEd,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;EAClC,YAAY;EACZ,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;;EAEE;IACE,sBAAsB;EACxB;AACF;;AAEA;;EAEE;IACE,kBAAkB;IAClB,UAAU;EACZ;AACF","sourcesContent":["\n.HeaderLayout {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: white;\n  height: 60px;\n  width: 100%;\n  border-bottom: 0.1px solid #d3d3d3;\n  color: #2c555b;\n\n  position: relative;\n}\n\n.HeaderLayout__innerBlock {\n  Font-family: 'DM Sans', sans-serif;\n  width: 800px;\n  display: flex;\n  justify-content: space-between;\n  font-weight: 100;\n  font-size: 20px;\n  text-align: right;\n  color: #2c555b;\n}\n@media (max-width: 800px) {\n\n  .HeaderLayout {\n    padding: 0 16px 0 16px;\n  }\n}\n\n@media (max-width: 450px) {\n\n  .HeaderLayout__logo {\n    position: absolute;\n    left: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
