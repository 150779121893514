// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelect {

    position: relative;
}
.CustomLIstForSelect {
    position: absolute;
    width: 800px;
    font-family: "DM Sans", sans-serif;
    padding-bottom: 0;
    vertical-align: text-bottom;
    border: 1px solid #b2bdc7;
    color: #2c555b;
    background-color: white;
    z-index: 2;
    margin-bottom: 200px;
  }
  .CustomLIstForSelect_position {
    font-family: "DM Sans", sans-serif;
    margin-top: 0;
    color: black;
    font-size: 20px;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 0;
    vertical-align: text-bottom;
    border-bottom: 1px solid #b2bdc7;
    width: 100%;
    height: 50px;
    color: #2c555b;
    cursor: pointer;
  }
  .CustomLIstForSelect_position:hover {
    background-color: #cdffff;
  }

  /* mobile */
@media (max-width: 800px) {
  .CustomLIstForSelect {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/CustomSelect/CustomSelect.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,YAAY;IACZ,kCAAkC;IAClC,iBAAiB;IACjB,2BAA2B;IAC3B,yBAAyB;IACzB,cAAc;IACd,uBAAuB;IACvB,UAAU;IACV,oBAAoB;EACtB;EACA;IACE,kCAAkC;IAClC,aAAa;IACb,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,2BAA2B;IAC3B,gCAAgC;IAChC,WAAW;IACX,YAAY;IACZ,cAAc;IACd,eAAe;EACjB;EACA;IACE,yBAAyB;EAC3B;;EAEA,WAAW;AACb;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".CustomSelect {\n\n    position: relative;\n}\n.CustomLIstForSelect {\n    position: absolute;\n    width: 800px;\n    font-family: \"DM Sans\", sans-serif;\n    padding-bottom: 0;\n    vertical-align: text-bottom;\n    border: 1px solid #b2bdc7;\n    color: #2c555b;\n    background-color: white;\n    z-index: 2;\n    margin-bottom: 200px;\n  }\n  .CustomLIstForSelect_position {\n    font-family: \"DM Sans\", sans-serif;\n    margin-top: 0;\n    color: black;\n    font-size: 20px;\n    padding-left: 20px;\n    padding-top: 11px;\n    padding-bottom: 0;\n    vertical-align: text-bottom;\n    border-bottom: 1px solid #b2bdc7;\n    width: 100%;\n    height: 50px;\n    color: #2c555b;\n    cursor: pointer;\n  }\n  .CustomLIstForSelect_position:hover {\n    background-color: #cdffff;\n  }\n\n  /* mobile */\n@media (max-width: 800px) {\n  .CustomLIstForSelect {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
