import React, {useEffect, useState} from "react";

import "./FormPage.css";
import PageError from "@modules/PageError/PageError";
import PageLoader from "@modules/PageLoader/PageLoader";
import Footer from "@shared/components/Footer";
import Header from "@shared/components/Header";
import HeaderPersonal from "@shared/components/HeaderPersonal";
import {formStore, orderStore} from "@store/index";
import {qrLinkStore} from "@store/index";
import {observer} from "mobx-react-lite";
import {useNavigate, useLocation} from "react-router-dom";
import {AsYouType} from 'libphonenumber-js'

import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Select from 'react-select'
import {components} from 'react-select'

import { getApiUrl } from "@api/getApiUrl";


import BLOCK_СONDITIONSORDER from "./components/BLOCK_СONDITIONSORDER";
import {useChooseSelectOrInput} from "../../hooks/use-choose-select-or-input";
import axios from "axios";

const SingleValue = (props: any) => {
  // const {name, price} = props.getValue()[0];
  // console.log(props.getValue());
  const {name, amount} = props.data;

  return (
    <components.SingleValue {...props}>
      <div style={{display: "flex"}}>
        <div>{name}</div>
        <div style={{
          // color: "darkgray",
          marginLeft: "auto",
          marginRight: "0",
          whiteSpace: "nowrap",
          fontWeight: 500,
          // background: "#fff5d7",
          // maxWidth: "8em",
        }}>{amount} ₽
        </div>
      </div>
      {/*<span>{name ? `name 22` : "placeholder"}</span>*/}
      {/*<span*/}
      {/*  // style={{color: "darkgray"}}*/}
      {/*>{price}</span>*/}
    </components.SingleValue>
  );
};

const Option = (props: any) => {
  const {name, amount} = props.data;

  return (
    <components.Option {...props}>
      <div style={{display: "flex"}}>
        <div>{name}</div>
        <div style={{
          // color: "darkgray",
          marginLeft: "auto",
          marginRight: "0",
          whiteSpace: "nowrap",
          fontWeight: 500,
          // background: "#fff5d7",
          // maxWidth: "8em",
        }}>{amount} ₽
        </div>
      </div>

    </components.Option>
  );
};


const FormPage = observer(() => {
  const {
    getClientTitleStore,
    ArrayWithAllInputsStore,
    ChangeArrayWithAllInputs,
    getShowWhatInputIsEmpty,
    ChageIsShowInfoHelp,
    ChageFocus,
    DeleteAllHelpers,
    ChageShowWhatInputIsEmpty,
    isLoading,
    ObjectWithInfoEmailInputStore,
    ShowList,
    itemListStore,
    Error,
    ChangeDataAboutForm,
    infoTextStore,
    clientLogoStore,
    namePersonStore,
    designStore,
    phoneStore,
    getDesignStore,
    getItemListStore,
    itemDescriptionStore,
    getButtonColor,
    getMenuColor,
    typeCustomerStore,
    clientIdStore,
    keyGenStore,
    employeeNameStoreForPOST,
    positionTypeStore
  } = formStore;

  const {
    getIsAgreeConditionPymentsCheckBox,
  } = orderStore;

  const {ChangeisLoadingQr_Link} = qrLinkStore;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    ChageShowWhatInputIsEmpty(false);
    ChangeisLoadingQr_Link(true);
    const curData: any = {
      key_gen: "48acf988-686f-4be4-bc36-82bf827c3b61",
    };

    location.search.split("&").forEach((line, i) => {
      curData.key_gen =
        line.split("=")[1] || "48acf988-686f-4be4-bc36-82bf827c3b61";
    });
    navigate("/formgen?key_gen=" + curData.key_gen);

    ChangeDataAboutForm(curData.key_gen);
  }, [
    navigate,
    location.search,
    ChangeDataAboutForm,
    ChageShowWhatInputIsEmpty,
    ChangeisLoadingQr_Link,
  ]);

  // const [email, setEmail] = useState<string>(namePerson);
  const [name, setName] = useState<string>(namePersonStore);
  const [prod, setProd] = useState<any>({});
  const [phone, setPhone] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [addingField, setAddingField] = useState<any>();

  // const InputClasses = classNames({
  //   "Block-InputEmail": !(
  //     getisActiveGenetalButton && !getValidationGeneralButton
  //   ),
  //   "Block-InputEmail_red":
  //     getisActiveGenetalButton && !getValidationGeneralButton,
  // });

  useEffect(() => {
    if (
      ArrayWithAllInputsStore.some(
        (elem: any) => elem.IsShowInfoHelp === true
      ) ||
      ObjectWithInfoEmailInputStore.IsShowInfoHelp ||
      ArrayWithAllInputsStore.some((elem: any) => elem.isopen === true)
    ) {
      document.addEventListener("click", DeleteAllHelpers);
    }

    return () => {
      document.removeEventListener("click", DeleteAllHelpers);
    };
  }, [
    ArrayWithAllInputsStore,
    ObjectWithInfoEmailInputStore,
    DeleteAllHelpers,
  ]);

  function fPost() {
    // postQr_Link(
    //   employeeNameStoreForPOST,
    //   clientIdStore,
    //   keyGenStore,
    //   ObjectWithInfoEmailInputStore?.value?.toLowerCase(),
    //   ArrayWithAllInputsStore?.[2].value,
    //   ArrayWithAllInputsStore?.[0].value,
    //   ArrayWithAllInputsStore?.[1].value,
    //   positionTypeStore,
    //   itemListStore,
    //   ArrayWithAllInputsStore?.[3]?.value
    // );

    // const x = parsePhoneNumber(phone || "");

    const POST_BODY = {
      items: [
        prod,
      ],
      employee: employeeNameStoreForPOST, //Обязательный параметр
      client_id: clientIdStore, //Обязательный параметр
      keyGen: keyGenStore, //Обязательный параметр
      emailCustomer: email,
      namePerson: name,
      phonePerson: phone,
    };

    axios
      .post(`${getApiUrl()}/test/api/webhook/orders/`, POST_BODY)
      .then((response: any) => {
        if (response.status !== 200) {
          if (response?.data?.result?.[0]) {
            throw Error(response?.data?.result?.[0]);
          }
          if (response?.data?.result?.error_message?.[0]) {
            throw Error(response?.data?.result?.error_message?.[0]);
          } else {
            throw Error("Что пошло не так! Попробуйте ввести данные заново");
          }
        }

        if (typeof response.data !== "object") {
          throw Error("Что пошло не так! Попробуйте ввести данные заново");
        }
        let urlFormPay = response?.data?.result?.urlFormPay;
        if (window.location.href.includes('giberno.com')) {
          urlFormPay = urlFormPay.replace('giberno.ru', 'giberno.com')
        }
        window.open(urlFormPay);
        // navigate(response?.data?.result?.urlFormPay);
      })
      .catch((err) => {
        return err.message;
      });
  }

  function isActiveButton() {
    return !(Object.keys(prod).length !== 0 && phone && name && getIsAgreeConditionPymentsCheckBox)
  }


  if (isLoading) {
    return <PageLoader/>;
  }

  if (Error) {
    return <PageError error={Error}/>;
  }

  const additionalBorder =
    itemListStore?.length - 7 > 0 ? (itemListStore?.length - 7) * 50 : 0;

  const options = itemListStore;

  var asYouType = new AsYouType();

  if (positionTypeStore === "PRICE_LIST") {
    return (
      <div
        className="FormPageInfoshopLayout"
      >
        <HeaderPersonal wrapperClassName="wrapperBlock" headerColor={getMenuColor}></HeaderPersonal>
        <div className="OrderContent px-4"
             style={{
               paddingBottom: "70px"
             }}>
          <div style={{
            fontSize: "28px",
            fontWeight: 600,
            lineHeight: "25px",
          }}
               className="py-2">
            {getClientTitleStore}
          </div>
          <div style={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "16px",
          }}
               className="py-2">
            {infoTextStore}
          </div>

          <Select
            options={options}
            menuPortalTarget={document.body}
            styles={{menuPortal: base => ({...base, zIndex: "9999 !important"})}}
            getOptionLabel={(option: any) => `${option.name} ${option.amount}`}
            getOptionValue={(option: any) => option.ItemID}
            components={{SingleValue, Option}}
            placeholder={"Выберите"}
            className="my-3"
            onChange={val => {
              setProd(val)
            }}
          />

          <FloatingLabel
            controlId="floatingInput"
            label="Введите ваш email"
            className="my-3"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={e => setEmail(e.target.value)}/>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput"
            label="Введите ваше имя"
            className="mb-3"
          >
            <Form.Control
              placeholder="ФИО"
              value={name}
              onChange={e => setName(e.target.value)}/>
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput4"
            label="Введите ваш телефон"
            className="mb-3"
          >
            <Form.Control
              value={phone}
              onFocus={e => {
                phone ? null : setPhone("+7")
              }}
              onChange={(e) => {
                let val = e.target.value;
                let purePhone = asYouType.input(val);
                setPhone(purePhone);
                asYouType.reset()
              }}
              // autoFocus={true}
              placeholder="name@example.com"
            />
          </FloatingLabel>


          {itemDescriptionStore?.enabled ?
            <FloatingLabel
              controlId="floatingInput"
              label={itemDescriptionStore?.itemDescriptionPlaceholder || ""}
              className="mb-3"
            >
              <Form.Control
                placeholder={""}
                value={addingField}
                onChange={e => setAddingField(e.target.value)}
              />
            </FloatingLabel> :
            null}

          <BLOCK_СONDITIONSORDER/>
          <div
            style={{
              zIndex: "3",
              position: "fixed",
              bottom: 10,
              left: "24px",
              right: "24px",
              // width: "100%",
            }}
            className="FooterLayout2 px-4"
          >
            <button
              disabled={isActiveButton()}
              style={{
                backgroundColor: `${getButtonColor}`,
                color: "#FFFFFF",
                opacity: isActiveButton() ? .5 : 1 ,
                width: "100%",
                height: "60px",
                boxSizing: "border-box",
                // TODO брать из апи
                // background: "#027BFF",
                zIndex: 3,
                border: "none",
                borderRadius: "41px",
                fontFamily: "Commissioner, sans-serif",
                fontSize: "17px",
                fontWeight: 600,
                letterSpacing: "0.03em",
                lineHeight: "22px",
              }}
              onClick={() => {
                fPost();
              }}
            >
              Продолжить покупку
            </button>
          </div>

        </div>
      </div>
    );
  } else {
    return (
      <div className="FormPageLayout">
        <Header/>
        <header className="FormPageLayout__header">Формирование оплаты</header>
        <form
          className="FormPageLayout__form"
          style={{
            marginBottom: itemListStore ? `${450 + additionalBorder}px` : "450px",
          }}
        >
          <div className="FormPageLayout__title">{getClientTitleStore}</div>
          {ArrayWithAllInputsStore.filter(
            (CurrentInput: any, i: any) => CurrentInput.IsEnabled
          ).map((CurrentInput: any, i: any, arr: any) => {
            console.log(CurrentInput, i, arr)
            const {isopen} = CurrentInput;
            const uniqKey =
              typeof isopen === "boolean" ? `select_${i}` : `input_${i}`;
            const InputOrSelectProps = {
              key: CurrentInput.placeholder,
              type: CurrentInput.type,
              name: CurrentInput.name,
              placeholder: CurrentInput.placeholder,
              value: CurrentInput.value,
              help: CurrentInput.help,
              currentNumber: i,
              IsEmpty: getShowWhatInputIsEmpty,
              IsShowInfoHelp: CurrentInput.IsShowInfoHelp,
              IsRequire: CurrentInput.IsRequire,
              onFocus: CurrentInput.onFocus,
              ChageFocus: ChageFocus,
              ChageIsShowInfoHelp: ChageIsShowInfoHelp,
              onChange: (type: any, value: any, name: any, isopen: any) => {
                if (type === "click" || typeof isopen !== "boolean") {
                  ChangeArrayWithAllInputs(value, name);
                }
              },
            };

            return useChooseSelectOrInput(
              isopen,
              ShowList,
              itemListStore,
              i,
              uniqKey,
              InputOrSelectProps,
              arr
            );
          })}
        </form>
        <Footer/>
      </div>
    );
  }
});

export default FormPage;
